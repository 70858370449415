import { MdShoppingBasket } from 'react-icons/md'
import { toast } from 'react-toastify'
import { actionTypes } from '../context/reducer'

// export const addToCart = async (
//   product,
//   q,
//   amount,
//   cartItems,
//   foodItems,
//   user,
//   dispatch,
// ) => {
// What will be if an item is added but long ago, price updated and people ordering now.
// if (cartItems.some((item) => item['pid'] === product.id)) {
//   toast.error('Item already in cart', {
//     icon: <MdShoppingBasket className="text-2xl text-cartNumBg" />,
//     toastId: 'itemAlreadyInCart',
//   })
// } else {
//   const data = {
//     id: Date.now(),
//     pid: product?.id,
//     uid: user?.uid,
//     quantity: q,
//     product,
//     amount,
//   }
//   const newCartItems = [...cartItems, data];
//   dispatch({
//     type: 'SET_CARTITEMS',
//     cartItems: newCartItems,
//   })
//   toast.success('Item added to cart', {
//     icon: <MdShoppingBasket className="text-2xl text-cartNumBg" />,
//     toastId: 'itemAddedtoCart',
//   })
//   calculateCartTotal(newCartItems, foodItems, dispatch)
// }
// }

export const addToCart = async (
  product,
  options, // object
  q,
  cartItems,
  dispatch,
  instruction,
) => {
  if (isDuplicate(product, options, cartItems)) {
    if (getExistingCartItem(product, cartItems)?.quantity == q) {
      toast.error('Item already in cart', {
        icon: <MdShoppingBasket className="text-2xl text-cartNumBg" />,
        toastId: 'itemAlreadyInCart',
      })
      return
    }
    updateCartItemQty(product, q, cartItems, dispatch)
  } else {
    const data = {
      id: Date.now(),
      pid: product?.id,
      quantity: q,
      product,
      options,
      instruction,
    }
    const newCartItems = [...cartItems, data]
    dispatch({
      type: 'SET_CARTITEMS',
      cartItems: newCartItems,
    })
    toast.success('Item added to cart', {
      icon: <MdShoppingBasket className="text-cartNumBg" />,
      toastId: 'itemAddedtoCart',
    })
    calculateCartTotal(newCartItems, dispatch)
  }
}

export const isDuplicate = (newProduct, newOptions, cartItems) => {
  if (!newProduct) return false
  const existingItemInCart = cartItems.find(
    (item) => item['pid'] === newProduct.id,
  )
  if (existingItemInCart) {
    const matchedAllOptions =
      Object.keys(newOptions).length ==
        Object.keys(existingItemInCart.options)?.length &&
      Object.keys(newOptions)?.every((groupId) => {
        const existingItemOptionInCart = Object.keys(
          existingItemInCart.options,
        ).find((existingOptionGroupId) => groupId == existingOptionGroupId)
        if (existingItemOptionInCart) {
          return (
            newOptions?.[groupId]?.length == existingItemOptionInCart?.length &&
            newOptions?.[groupId]?.every((newP) =>
              existingItemOptionInCart?.find((p) => p.id == newP.id),
            )
          )
        }
        return false
      })

    if (matchedAllOptions) {
      return true
    }
  }

  return false
}

export const getExistingCartItem = (newProduct, cartItems) => {
  if (!newProduct) return null
  return cartItems.find((item) => item['pid'] === newProduct.id)
}

export const dispatchShopId = (dispatch, shopId) => {
  dispatch({
    type: actionTypes.SET_SHOP_ID,
    shopId,
  })
  localStorage.setItem('shopId', JSON.stringify(shopId))
}

export const dispatchTableNumber = (dispatch, tableNumber) => {
  dispatch({
    type: actionTypes.SET_TABLE_NUMBER,
    tableNumber,
  })
  localStorage.setItem('tableNumber', tableNumber)
}

export const dispatchtUserCartItems = (uid, items, dispatch) => {
  const cartItems = items.filter((item) => item.uid === uid)
  dispatch({
    type: 'SET_CARTITEMS',
    cartItems: cartItems,
  })

  return cartItems
}

export const fetchUserCartData = async (user, dispatch) => {
  if (user) {
    // await firebaseFetchAllCartItems()
    //   .then((data) => {
    //     const userCart = dispatchtUserCartItems(user.uid, data, dispatch)
    //     localStorage.setItem('cartItems', JSON.stringify(userCart))
    //   })
    //   .then(() => {})
    //   .catch((e) => {
    //     console.log(e)
    //   })
  } else {
    localStorage.setItem('cartItems', 'undefined')
  }
}

export const fetchFoodData = async (dispatch) => {
  // await firebaseFetchFoodItems()
  //   .then((data) => {
  //     dispatch({
  //       type: 'SET_FOOD_ITEMS',
  //       foodItems: data,
  //     })
  //   })
  //   .then(() => {})
  //   .catch((e) => {
  //     console.log(e)
  //   })
}

export const getFoodyById = (menu, pid) => {
  return menu.find((item) => item.id === pid)
}

//  Update cart item State
export const updateCartItemState = async (cartItems, item, dispatch) => {
  const index = cartItems.findIndex((cartItem) => cartItem.id === item.id)
  if (index !== -1) {
    cartItems[index] = item
  }
  dispatch({
    type: 'SET_CARTITEMS',
    cartItems: cartItems,
  })
  // await firebaseUpdateCartItem(item)
  //   .then(() => {})
  //   .catch((e) => {
  //     console.log(e)
  //   })
}

// Update Cart Item Quantity
export const updateCartItemQty = async (item, val, cartItems, dispatch) => {
  const index = cartItems.findIndex((cartItem) => cartItem.pid === item.pid)
  if (index !== -1) {
    cartItems[index].quantity = val
    dispatch({
      type: 'SET_CARTITEMS',
      cartItems: cartItems,
    })
    calculateCartTotal(cartItems, dispatch)
    // await firebaseUpdateCartItem(cartItems[index])
    //   .then(() => {})
    //   .catch((e) => {
    //     console.log(e)
    //   })
  }
}

//  Delete Cart Item
export const deleteCartItem = async (item, cartItems, dispatch) => {
  const index = cartItems.findIndex((cartItem) => cartItem.pid === item.pid)
  console.log(item, index, cartItems)
  if (index !== -1) {
    cartItems.splice(index, 1)
    dispatch({
      type: 'SET_CARTITEMS',
      cartItems: cartItems,
    })
    calculateCartTotal(cartItems, dispatch)
    toast.success('Item deleted successfully')

    // await firebaseDeleteCartItem(item)
    //   .then(() => {})
    //   .catch((e) => {
    //     console.log(e)
    //   })
  }
}

// Calculate Total Price Round to 2 decimal places
export const calculateCartTotal = (cartItems, dispatch) => {
  let total = getCartTotal(cartItems)
  dispatch({
    type: 'SET_CART_TOTAL',
    cartTotal: total.toFixed(2),
  })
}

export const getCartTotal = (cartItems) => {
  let total = 0
  cartItems.forEach((item) => {
    // const foodItem = getFoodyById(foodItems, item.pid)
    const allOptionAmount = Object.values(item.options)
      ?.flatMap((op) => op)
      ?.map((op) => Number(op?.price))
      ?.reduce((a, b) => a + b, 0)
    const itemTotal = (item.product.price + allOptionAmount) * item.quantity
    total += itemTotal
  })
  return total
}

// Empty Cart
export const emptyCart = async (cartItems, dispatch) => {
  if (cartItems.length > 0) {
    dispatch({
      type: 'SET_CARTITEMS',
      cartItems: [],
    })
    calculateCartTotal(cartItems, dispatch)
    // await firebaseEmptyUserCart(cartItems)
    //   .then(() => {})
    //   .catch((e) => {
    //     console.log(e)
    //   })
  } else {
    toast.warn('Cart is already empty')
  }
}

export const toggleCart = (dispatch, showCart) => {
  dispatch({
    type: actionTypes.TOGGLE_CART,
    showCart: !showCart,
  })
}

export const hideCart = (dispatch) => {
  dispatch({
    type: actionTypes.TOGGLE_CART,
    showCart: false,
  })
}

// Hide Cart
export const hideContactform = (dispatch) => {
  dispatch({
    type: 'TOGGLE_CONTACT_FORM',
    showContactForm: !true,
  })
}

export const shuffleItems = (items) => {
  let currentIndex = items.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[items[currentIndex], items[randomIndex]] = [
      items[randomIndex],
      items[currentIndex],
    ]
  }

  return items
}

export const logout = async (user, dispatch, navigate) => {
  if (user) {
    await Promise.resolve()
      .then(() => {
        dispatch({
          type: 'SET_USER',
          user: null,
        })
        dispatch({
          type: 'SET_CARTITEMS',
          cartItems: [],
        })
        // turn off adminMode
        dispatch({
          type: 'SET_ADMIN_MODE',
          adminMode: false,
        })

        localStorage.setItem('user', 'undefined')
        localStorage.setItem('adminMode', 'undefined')
        localStorage.removeItem('cartItems')
        navigate('/')
      })
      .catch((e) => {
        console.log(e)
      })
  } else {
    console.log('You are not logged in')
  }
}

export const ToggleAdminMode = (dispatch, state) => {
  dispatch({
    type: 'SET_ADMIN_MODE',
    adminMode: state,
  })
  localStorage.setItem('adminMode', JSON.stringify(state))
  console.log(state)
}

export const isAdmin = (user) => {
  let isAdmin =
    user?.email == 'bentilshadrack72@gmail.com' ||
    user?.email == 'admin@test.com'
  return isAdmin
}

// get user
export const getUserData = async (user) => {
  return user
  // return await firebaseGetUser(user.uid)
}

// update currentUser
export const updateUserData = async (user, dispatch, alert) => {
  // await firebaseUpdateUser(user)
  //   .then(() => {
  //     dispatch({
  //       type: 'SET_USER',
  //       user: user,
  //     })
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //   })
  //   .then(() => {
  //     localStorage.setItem('user', JSON.stringify(user))
  //     alert && toast.success('User data updated successfully')
  //   })
}

// get all users
export const dispatchUsers = async (dispatch) => {
  // await firebaseGetAllUsers()
  //   .then((users) => {
  //     dispatch({
  //       type: 'SET_USERS',
  //       users: users,
  //     })
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //   })
}
export const getAllUser = async () => {
  // await firebaseGetAllUsers()
  //   .then((users) => {
  //     return users
  //   })
  //   .catch((e) => {
  //     console.log(e)
  //   })
}
// delete food
export const deleteCartItem2 = async (food, foodItems, dispatch) => {
  // await firebaseDeleteFood(food.id)
  // remove food from foodItems
  const foodIndex = foodItems.indexOf(food)
  if (foodIndex !== -1) {
    foodItems.splice(foodIndex, 1)
  }
  dispatch({
    type: 'SET_FOOD_ITEMS',
    foodItems,
  })
  toast.success('Item deleted successfully')
}
