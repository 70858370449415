import { useStateValue } from '../../context/StateProvider'
import CartBody from './Body'
import { motion } from 'framer-motion'
import EmptyCart from '../EmptyCart'
import NotFound from '../NotFound'
import { useState, useEffect, useRef } from 'react'
import Loader from '../Loader'
import { MdAdd, MdClose, MdMinimize } from 'react-icons/md'
import { menuService } from '../../service'
import {
  addToCart,
  hideCart,
  isDuplicate,
  getExistingCartItem,
} from '../../utils/functions'
import { FaMinus, FaPlus } from 'react-icons/fa'

const Details = ({ id, hide }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState({})
  const [selectedOptionsError, setSelectedOptionsError] = useState({})
  const [q, setQ] = useState(1)
  const instructionRef = useRef(null)
  const requiredRef = useRef([])
  const [{ cartItems }, dispatch] = useStateValue()

  useEffect(() => {
    setVisible(!!id)
    setLoading(true)
    menuService.getOne(id).then((data) => {
      if (!data) return
      setLoading(false)
      setData(data)
      setQ(getExistingCartItem(data.product, cartItems)?.quantity || 1)
      setSelectedOptions({})
    })
  }, [id])

  const handleQ = (n) => {
    setQ((prevQ) => Math.max(1, prevQ + n))
  }

  const setOption = (id, optionProductItem, checked, isSingle) => {
    setSelectedOptions((prevList) => {
      const newList = { ...prevList }
      let optionValues = [...(newList[id] || [])]
      const existingIndex = optionValues?.findIndex(
        (p) => p.id == optionProductItem?.id,
      )

      if (existingIndex >= 0) {
        optionValues?.splice(existingIndex, existingIndex + 1)
      }

      if (checked) {
        if (isSingle) {
          optionValues = []
        }
        optionValues = optionValues.concat({
          id: optionProductItem?.id,
          name: optionProductItem?.name,
          price: optionProductItem?.price,
          groupName: optionProductItem?.productGroup?.name,
        })
      }

      newList[id] = optionValues
      if (optionValues?.length == 0) {
        delete newList[id]
      }

      return newList
    })
  }

  const allOptionAmount = Object.values(selectedOptions)
    ?.flatMap((op) => op)
    ?.map((op) => Number(op?.price))
    ?.reduce((a, b) => a + b, 0)
  const total = (data?.product?.price + allOptionAmount) * q || 0

  const isRequiredSelected = (updatedList) => {
    return Object.values(updatedList).findIndex((val) => val == true) == -1
  }
  const updateRequiredSelectedError = () => {
    const options = data?.options || []
    const updatedList = {}
    options.forEach((op) => {
      const selected = selectedOptions[op?.group?.id]
      if (!selected || op?.group?.minimumRequired > selected?.length) {
        updatedList[op?.group?.id] = true
      } else {
        updatedList[op?.group?.id] = false
      }
    })
    setSelectedOptionsError(updatedList)
    return updatedList
  }
  const add = () => {
    const updatedList = updateRequiredSelectedError()
    console.log("all selected: ", isRequiredSelected(updatedList))
    if (!isRequiredSelected(updatedList)) {
      const options = data?.options || []
      const index = options.findIndex((op) => updatedList[op.group.id] == true)
      console.log('requiredRef', requiredRef, index)
      requiredRef?.current[index]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })

      return
    }

    addToCart(
      data?.product,
      selectedOptions,
      q,
      cartItems,
      dispatch,
      instructionRef?.current?.value,
    )
  }

  console.log('cartItems', cartItems)
  console.log('details', data)
  console.log('selectedOptions', selectedOptions)
  console.log('selectedOptions Required Errors', selectedOptionsError)

  const isAlreadyAddedToCart = isDuplicate(
    data?.product,
    selectedOptions,
    cartItems,
  )

  return (
    <div className={visible ? 'visible' : 'invisible'}>
      <motion.div
        initial={{ opacity: 0, x: 200 }}
        animate={{ opacity: 1, x: '-50%', y: '-50%' }}
        exit={{ opacity: 0, x: 200 }}
        style={{ maxHeight: 'calc(100vh - 70px)', overflowY: 'auto' }}
        className={`w-full md:w-[500px] lg:w-[600px] overflow-auto rounded-bl-[0] rounded-br-[0] sm:rounded-bl-[1rem] sm:rounded-br-[1rem]  rounded-tl-[1rem] rounded-tr-[1rem] bg-white md:backdrop-blur-sm flex flex-col z-[101] drop-shadow-xl fixed mid-screen-m`}
      >
        <div className="w-full flex items-center justify-between px-4 py-4 cursor-pointer absolute left-0 right-0 top-0 z-50">
          <div className="flex items-center gap-4">
            <motion.div whileTap={{ scale: 0.8 }} onClick={() => hide()}>
              <MdClose className="text-textColor text-2xl " />
            </motion.div>
          </div>
        </div>

        <div
          className="flex-grow"
          style={{ maxHeight: '100%', overflowY: 'auto' }}
        >
          {loading ? (
            <div className="flex items-center h-full">
              <Loader />
            </div>
          ) : data ? (
            <CartBody
              data={data}
              onOptionChange={setOption}
              instructionRef={instructionRef}
              requiredErrors={selectedOptionsError}
              requiredRef={requiredRef}
            />
          ) : (
            <div className="flex items-center h-full">
              <NotFound text={'Item not available'} />
            </div>
          )}
        </div>

        <div className="w-full flex items-center justify-between px-4 py-4 bg-white cursor-pointer sticky absolutex left-0 right-0 bottom-0">
          <div className="flex items-center gap-4 w-full">
            <div className="flex items-center">
              <button onClick={() => handleQ(-1)}>
                <FaMinus className="text-textColor" />
              </button>
              <div className="px-4">{q}</div>
              <button onClick={() => handleQ(1)}>
                <FaPlus className="text-textColor" />
              </button>
            </div>
            <div className="flex-grow w-full">
              <button
                className="secondary-button w-full rounded-[1.5rem] h-10 text-sm sm:text-md shadow-xl leading-4"
                disabled={loading}
                onClick={add}
              >
                {isAlreadyAddedToCart ? 'Update Cart' : 'Add to Cart'} (৳
                {total.toFixed(2)})
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  )
}

export default Details
