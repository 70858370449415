import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from 'react'
import PickMeals from '../Assets/order-food.png'
import ChooseMeals from '../Assets/choose-image.png'
import DeliveryMeals from '../Assets/trolley.png'
import {
  GiFruitTree,
  GiChickenOven,
  GiBeerBottle,
  GiBowlOfRice,
} from 'react-icons/gi'
import { MdOutlineIcecream } from 'react-icons/md'
import { FaFish, FaList, FaSearch } from 'react-icons/fa'
import FilterButton from './FilterButton'
import NotFound from './NotFound'
import Loader from './Loader'
import FoodItem from './FoodItem'
import { motion } from 'framer-motion'
import { menuService } from '../service'
import { useStateValue } from '../context/StateProvider'
import Details from './Details/index'
import { toast } from 'react-toastify'

const Menu = () => {
  const [filter, setFilter] = useState('all')
  const [loading, setLoading] = useState(false)
  const [items, setitems] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const parentRef = useRef(null)
  const containerRef = useRef(null)
  const [{ shopId }, dispatch] = useStateValue()
  const [searchTerm, setSearchTerm] = useState('')

  function fetchData () {
    setLoading(true)
    menuService.getAll(shopId, null, searchTerm).then((list) => {
      setLoading(false)
      setitems(list)
    })
  }

  useEffect(() => {
    if (shopId) {
      fetchData()
    }
  }, [shopId])

  const categories = items.map((item) => item.category)

  const selectItem = (id) => {
    clearTimeout(parentRef.current)
    parentRef.current = setTimeout(() => {
      setSelectedId(id)
    }, 0)
  }

  useEffect(() => {
    return () => clearTimeout(parentRef.current)
  }, [])

  function debounce(callback, delay) {
    let ref = null
    return () => {
      clearTimeout(ref)
      ref = setTimeout(callback, delay)
    }
  }

  const handleSearch = (e) => {
    const handler = debounce(() => setSearchTerm(e.target.value), 300)
    handler()
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchData()
    }
  }

  return (
    <>
     {selectedId && <Details id={selectedId} hide={() => setSelectedId(null)} />}

      <div className="pt-6 lg:pt-16">
        <div className="work-section-top">
          <p className="primary-subheading">Menu</p>
          <h1 className="primary-heading">Our Dishes</h1>
        </div>

        <div
          className={`w-full py-10 mb-0 lg:mb-4 flex items-center justify-start lg:justify-center  h-auto gap-4 md:gap-8  px-2  overflow-x-scroll scrollbar-hidden  scroll-smooth`}
        >
          <FilterButton
            category={{ name: 'All', urlParam: 'all', imageUrl: <FaList /> }}
            filter={filter}
            setFilter={setFilter}
          />
          {categories.map((category) => {
            return (
              <FilterButton
                key={category.id}
                category={category}
                filter={filter}
                setFilter={setFilter}
              />
            )
          })}
        </div>

        <div className="mb-4 lg:mb-6 flex items-center rounded-full bg-white w-80 pl-3">
          <FaSearch className="h-full text-color-dark" />
          <input
            type="text"
            name="search"
            placeholder="search here..."
            // value={searchTerm}
            onChange={handleSearch}
            onKeyDown={handleKeyPress}
            className="px-4 py-2 rounded-r-full outline-none w-full"
          />
        </div>

        <div>
          {items?.map((item, i) => {
            const category = item.category
            const products = item.products
            return (
              <div
                className="relative mb-4 lg:mb-12"
                key={i}
                id={`${category.name?.split(' ').join('-')}`}
              >
                <div className="flex gap-4 items-center">
                  {category.imageUrl && (
                    <img
                      src={category.imageUrl}
                      className="h-full max-h-[500px] object-contain absolute left-[-50px] top-[-50px] opacity-10 z-[-1]"
                    />
                  )}
                  <h2 className="primary-text relative z-10 md:mb-6 font-bold">
                    {category.name}
                  </h2>
                </div>

                <motion.div
                  ref={containerRef}
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 200 }}
                  className={`w-full mb-4 grid grid-cols-1 sm:grid-cols-4 items-center gap-2 lg:gap-6 relative z-10`}
                >
                  {products?.map((item) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        selectItem(item.id)
                      }}
                    >
                      <FoodItem key={item.id} item={item} />
                    </div>
                  ))}
                </motion.div>
              </div>
            )
          })}
          {loading && <Loader progress={'Fetching Food Items.....'} />}
          {!loading && items.length == 0 && (
            <NotFound text="No Food Items Available " />
          )}
        </div>
      </div>
    </>
  )
}

export default Menu
