import CartItem from './Item.jsx'
import CartTotal from './CartTotal'
import { useStateValue } from '../../context/StateProvider'
import { motion } from 'framer-motion'
import { FaCartPlus, FaPlus } from 'react-icons/fa'

const CartBody = ({
  data,
  onOptionChange,
  instructionRef,
  requiredErrors,
  requiredRef,
}) => {
  const {
    id,
    name,
    price,
    discount,
    category,
    imageUrl,
    description,
  } = data.product

  const { options } = data

  const [{ cartItems }, dispatch] = useStateValue()

  return (
    <div className="w-full flex flex-col">
      <div className="col-span-2 flex-grow flex items-center justify-between bg-cartBg">
        <motion.img
          className="w-full h-60 sm:h-72 lg:h-96 object-cover cursor-pointer object-center"
          alt={description}
          src={imageUrl}
        />
      </div>

      <div className="p-4 col-span-3 flex-growx sm:w-full h-full flex items-end justify-end flex-col relative bg-white rounded-lg p-desc-curv">
        <div className="pt-2 md:pt-0 pr-2 md:pb-2 w-full">
          <h2 className="text-textColor font-bold text-xl">{name}</h2>
          <div className="flex items-center gap-4 my-2">
            <p className="text-base text-headingColor font-semibold">
              <span className="text-sm text-accent text-lg">৳</span> {price}
            </p>
            {discount > 0 && (
              <p className="text-base text-gray-400 line-through">
                <span className="text-sm">৳</span> {discount}
              </p>
            )}
          </div>
          <div>
            <span className="text-accent-2 border-b-2 border-accent text-xs">
              DESTAILS
            </span>
            <p className="mt-1 text-sm text-gray-500 mb-4 mt-2">
              {description}{' '}
            </p>
          </div>
          <div>
            {options?.map((option, i) => {
              const { group, products } = option
              return (
                <div
                  className="border border-accent rounded-lg p-4 mb-4 bg-accentBg"
                  key={group?.id}
                >
                  <div className="flex gap-4 justify-between">
                    <div>
                      <h4 className="mb-1 font-semibold text-textColor">
                        {group.name}
                      </h4>
                      <p className="mb-2 text-xs">{group.description}</p>
                    </div>
                    <div
                      ref={(el) => requiredRef.current[i] = el}
                      data-groupid={group.id}
                    >
                      {requiredErrors[group.id] == true && (
                        <b className="text-red-500 text-sm">Required</b>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    {products?.map((p) => {
                      return (
                        <div
                          className="flex justify-between items-center gap-4"
                          key={p.id}
                        >
                          <div className="flex items-center gap-4 text-color-dark">
                            <input
                              type={
                                group.minimumRequired == 1
                                  ? 'radio'
                                  : 'checkbox'
                              }
                              name={group.name}
                              onChange={(e) => {
                                onOptionChange(
                                  group.id,
                                  p,
                                  e.target.checked,
                                  group.minimumRequired == 1,
                                )
                              }}
                            />{' '}
                            {p.name}
                          </div>
                          <div className="text-color-dark">
                            {p.price ? (
                              `৳${p.price}`
                            ) : (
                              <span className="text-green-500">Free</span>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <div>
            <h4 className="">Special Instruction</h4>
            <textarea
              className="mt-1 p-2 text-sm border-2 w-full rounded-md"
              placeholder="Write here..."
              rows={3}
              ref={instructionRef}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartBody
